<i18n lang="yaml">
pt:
  noData: "Nenhuma opção encontrada"
  loading: "Procurando opções..."
en:
  noData: "No options found"
  loading: "Searching options..."
</i18n>
<template>
  <div
    class="deck-select"
    :class="classes"
  >
    <deck-label
      v-if="label"
      :text="label"
      :input-ref="computedId"
      :size="size === 'medium' ? 'small' : size"
      :hint="hint"
    />
    <!--
      Emitted when inner v-autocomplete component has its input changed by user interaction. Won't be emitted on individual chips close button click.
      @event change
      @type {any}
    -->
    <!--
      Emitted when the inner v-autocomplete component is focused
      @event focus
      @type {Event}
    -->
    <!--
      Emitted when the inner v-autocomplete component is being focused out (it will bubble, in contrast to the blur event, which won’t).
      @event focusout
      @type {Event}
    -->
    <v-autocomplete
      :id="computedId"
      ref="autocompleteRef"
      :model-value="deckInputMixin__internalValue"
      :items="computedItems"
      :aria-label="ariaLabel || label"
      :required="required"
      :disabled="disabled"
      :multiple="multiple"
      :append-icon="null"
      :placeholder="placeholder"
      :menu-props="computedMenuProps"
      :rules="rules"
      :auto-select-first="scrollOnOpen"
      :loading="loading"
      :return-object="returnObject"
      :menu-icon="hasMenuOpen ? 'fas fa-magnifying-glass' : '$dropdown'"
      :custom-filter="filterAlgorithm"
      class="deck-select__autocomplete"
      variant="outlined"
      density="compact"
      hide-details="auto"
      :menu="hasMenuOpen"
      @update:model-value="onInput"
      @blur="onBlur"
      @update:search="searchInput = $event"
      @focus="$emit('focus')"
      @focusout="$emit('focusout')"
      @update:menu="hasMenuOpen = $event"
    >
      <template #item="{ item, props }">
        <deck-select-v-list-item
          :ref="`item-${item.value || item.raw.label || item.raw.selectAll}`"
          v-test-id="item.value ? `deck-select-item-${item.value}` : undefined"
          :default-props="props"
          :disabled="loading || disabled || props.disabled || item.raw.disabled"
          :indent-items="isGrouped"
          :is-searching="isSearching"
          :item="item.raw"
          :items-selection="itemsSelection"
          :multiple="multiple"
          :select-all-disabled="selectAllDisabled"
        >
          <deck-select-list-item-select-all
            v-if="'selectAll' in item.raw && (!selectAllDisabled || !isSearching)"
            :disabled="selectAllDisabled && itemsSelection.isNone"
            :items-selection="itemsSelection"
            :value="itemsSelection.isAll"
            @select-all="onSelectAll(enrichedItems)"
          />
          <deck-select-list-item-group-label
            v-else-if="'label' in item.raw"
            :disabled="selectAllDisabled && item.raw.groupSelection.isNone"
            :hide-checkbox="!multiple || isSearching || selectAllDisabled || item.raw.exclusive"
            :item="item.raw"
            @select-all="onSelectAll((enrichedItems.find(group => group.label === item.raw.label)).items)"
          />
          <deck-select-list-item-selectable
            v-else
            :disabled="disabled"
            :item="item.raw"
            :with-checkbox="multiple"
          >
            <template #item-content>
              <!--
                @slot For rendering custom content inside the list items in the menu. Overrides the default text. Keeps checkboxes and radios visible.
                @binding {object} item The item to render
                @binding {object} attrs The attributes to attach to the list item
              -->
              <slot
                name="item-content"
                v-bind="{ item: item.raw, props }"
              />
            </template>
          </deck-select-list-item-selectable>
        </deck-select-v-list-item>
      </template>

      <template #selection="{ item }">
        <deck-select-selection
          v-if="canRenderSelections && _isObject(item.raw)"
          :item="item.raw"
          :multiple="multiple"
          :disabled="disabled"
          :size="size"
          :is-searching="isSearching"
          class="deck-select__selection"
          @clear-item="onInput(deckInputMixin__internalValue.filter(i => i !== item.value))"
        >
          <template #selection="{ item: selectionItem }">
            <!--
              @slot For rendering custom content on individual current selection states of the autocomplete. Overrides the default text.
              @binding {object} item Individual computed item reference
            -->
            <slot
              name="selection"
              v-bind="{ item: selectionItem }"
            />
          </template>

          <template #selected-item-actions="{ hover }">
            <!--
              @slot For reserved space at the end of the selected item to render actions/controls.
              @binding {object} item Individual computed item reference
              @binding {boolean} hover Whether the mouse is hovering the inner part of the selected item
            -->
            <slot
              name="selected-item-actions"
              v-bind="{ item: item.raw, hover }"
            />
          </template>
        </deck-select-selection>
      </template>

      <template #append-inner>
        <div class="deck-select__append-icons">
          <transition name="fade">
            <div
              v-if="canClearAll"
              class="deck-select__clear-button"
            >
              <deck-button
                :text="$t('global.clear')"
                size="small"
                kind="ghost"
                icon="xmark"
                color="controls"
                is-ready
                @mousedown.stop="multiple ? onInput([], true) : onInput(null, true)"
              />
            </div>
          </transition>
        </div>
      </template>

      <template #progress>
        <deck-progress
          v-show="!hasMenuOpen"
          bottom
          indeterminate
          height="2px"
          :border-radius="4"
          :inset-offset="1"
        />
      </template>

      <template #append-item>
        <div
          v-if="computedActions.length || $slots['prepend-actions'] || $slots['append-actions']"
          class="deck-select__menu-append-wrapper"
        >
          <slot name="prepend-actions" />

          <div
            v-if="computedActions.length"
            class="d-flex align-center g-1"
          >
            <deck-button
              v-for="(action, index) in computedActions"
              :key="index"
              v-bind="action"
              @click="handleActionClick(action.action)"
            />
          </div>

          <slot name="append-actions" />
        </div>

        <deck-progress
          v-if="loading && hasMenuOpen"
          bottom
          indeterminate
          height="4px"
          class="deck-select__inner-menu-loading"
        />
      </template>

      <template #no-data>
        <deck-select-list-item
          v-bind="{ text: t('noData'), value: null }"
          class="px-3"
          :text-trimmer-props="{ lineClamp: 0 }"
          :class="{ 'pt-2': multiple }"
        >
          <p
            v-if="loading"
            class="mb-0"
          >
            {{ t("loading") }}
          </p>

          <!-- @slot For rendering custom content when no data is available. Overrides the default text. -->
          <slot
            v-else
            name="no-data"
          >
            {{ noDataText || t("noData") }}
          </slot>
        </deck-select-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script lang="ts">
import { slugify } from '~/assets/javascript/utils';
import deckInputMixin from '~/mixins/deckInputMixin';
import type { VAutocomplete } from 'vuetify/components/VAutocomplete';
import { ref } from 'vue';
import DeckSelectVListItem from './_v-list-item';
import DeckSelectListItem from './_list-item';
import DeckSelectListItemSelectAll from './_list-item-select-all';
import DeckSelectListItemGroupLabel from './_list-item-group-label';
import DeckSelectListItemSelectable from './_list-item-selectable';
import DeckSelectSelection from './_selection';

const MENU_HEADER_HEIGHT = 40;
const UNLABELLED_GROUP_MATCHER = '-';
export default defineComponent({
  MENU_HEADER_HEIGHT,
  name: 'DeckSelect',
  components: {
    DeckButton: defineAsyncComponent(() => import('~/deck/button')),
    DeckLabel: defineAsyncComponent(() => import('~/deck/label')),
    DeckProgress: defineAsyncComponent(() => import('~/deck/progress')),
    DeckSelectVListItem,
    DeckSelectListItem,
    DeckSelectListItemSelectAll,
    DeckSelectListItemGroupLabel,
    DeckSelectListItemSelectable,
    DeckSelectSelection,
  },
  mixins: [deckInputMixin],
  props: {
    /**
     * The id of the input.
     * @type {string}
     * @default null
     */
    inputId: {
      type: String,
      default: null,
    },

    /**
     * The label to display.
     * @type {string}
     * @default null
     */
    label: {
      type: String,
      default: null,
    },

    /**
     * The aria-label to apply to the input.
     * @type {string}
     * @default null
     */
    ariaLabel: {
      type: String,
      default: null,
    },

    items: {
      type: Array,
      required: true,
      validator(items) {
        const isValidItem = item => 'text' in item && typeof item.text === 'string'
        && 'value' in item
        && (item.icon ? typeof item.icon === 'string' : true)
        && (item.disabled ? typeof item.disabled === 'boolean' : true)
        && (item.disabledHint ? typeof item.disabledHint === 'string' : true);

        return items.every((item) => {
          if ('label' in item && 'items' in item) {
            return typeof item.label === 'string'
            && (item.icon ? typeof item.icon === 'string' : true)
            && Array.isArray(item.items)
            && item.items.every(isValidItem);
          }

          return isValidItem(item);
        });
      },
    },

    /**
     * The value of the select. Can be a string or number, when multiple is false, or an array of strings when multiple is true.
     * @type {string | Array}
     * @default null
     */
    // eslint-disable-next-line vue/no-unused-properties
    modelValue: { // it is used by mixin
      type: [String, Array, Object, Number],
      default: null,
    },

    /**
     * Whether the select is required.
     * @type {boolean}
     * @default true
     */
    required: {
      type: Boolean,
      default: true,
    },

    /**
     * Whether the select is disabled.
     * @type {boolean}
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the select allows multiple values.
     * @type {boolean}
     * @default false
     */
    multiple: {
      type: Boolean,
      default: false,
    },

    /**
     * The size of the input.
     * @type {'dense' | 'medium' | string}
     * @default 'dense'
     */
    size: {
      type: String,
      default: 'dense',
    },

    /**
     * The hint to display.
     * @type {string}
     * @default null
     */
    hint: {
      type: String,
      default: null,
    },

    /**
     * The placeholder to display.
     * @type {string}
     * @default null
     */
    placeholder: {
      type: String,
      default: null,
    },

    /**
     * The rules to validate against.
     * @type {Array}
     * @default []
     */
    rules: {
      type: Array,
      default: () => [],
    },

    /**
     * Whether the select is loading.
     * @type {boolean}
     * @default false
     */
    loading: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the select returns an object instead of a string.
     * @type {boolean}
     * @default false
     */
    returnObject: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the select items should be unordered.
     * @type {boolean}
     * @default false
     */
    unorderedItems: {
      type: Boolean,
      default: false,
    },

    /**
     * The props to pass to the menu.
     * @type {Object}
     * @default {}
     */
    menuProps: {
      type: Object,
      default: () => ({}),
    },

    /**
     * Whether the select should allow select all items when the select all button is clicked.
     * @type {boolean}
     * @default false
     */
    selectAllDisabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the select should scroll to the first active item when the menu is opened.
     * Useful for long lists with conditionally enabled items.
     * @type {boolean}
     * @default false
     */
    scrollOnOpen: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the menu width should be forced to match the input width.
     * @type {boolean}
     * @default false
     */
    matchMenuWidthToInput: {
      type: Boolean,
      default: false,
    },

    /**
     * The text to display when no data is available.
     * @type {string}
     * @default 't("noData")'
     */
    noDataText: {
      type: String,
      default: '',
    },

    /**
     * An array of deck-buttons to display at the bottom of the menu. On each button, Include an `action` property with the function to call when the button is clicked.
     * @type {string}
     * @default ''
     */
    actions: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['focus', 'focusout', 'menuOpen', 'menuClose', 'blur'],
  setup() {
    const { t } = useI18n();
    const autocompleteRef = ref<VAutocomplete>();

    return { t, autocompleteRef };
  },
  data() {
    return {
      menuMaxWidth: null,
      searchInput: '',
      hasMenuOpen: false,
    };
  },
  computed: {
    computedId() {
      return this.inputId || `deck-select-${this.$.uid}`;
    },
    canRenderSelections() {
      // This is necessary because the vuetify will use the modelValue type to determine the type of the input
      // if the items is not present, and sometimes the modelValue is an array of strings and the items is not present yet
      // the vuetify will render the input as a string input, and this is not the expected behavior because the items are objects
      // and the vuetify should render the input as object, not string
      return _isEmpty(this.deckInputMixin__internalValue) || (this.items && this.items.length > 0);
    },
    /**
     * Enrich items and groups with their current selection state to help define
     * the behavior for the selectAll checkboxes
     */
    enrichedItems() {
      return this.items.map((item) => {
        const items = _cloneDeep([].concat(item.items || item));

        items.forEach((item) => {
          if (this.multiple) {
            if (Array.isArray(this.deckInputMixin__internalValue)) {
              item.selected = this.deckInputMixin__internalValue.includes(item.value);
            }
          } else {
            item.selected = this.deckInputMixin__internalValue === item.value;
          }
        });

        if (!this.isGrouped) return items[0];

        const groupSelection = this.getItemsSelectionState(items);

        return {
          ...item,
          items,
          groupSelection,
        };
      });
    },
    sortedEnrichedItems() {
      if (this.isGrouped) {
        return this.enrichedItems.map(group => ({
          ...group,
          items: Array.from(group.items).sort((a, b) => a.text.localeCompare(b.text)),
        })).sort((a, b) => a.label.localeCompare(b.label));
      }

      return Array.from(this.enrichedItems).sort((a, b) => a.text.localeCompare(b.text));
    },

    /**
     * Flat the items to pass them in the way v-autocomplete expects it.
     * Group labels are sent as items with special properties right before its group first item.
     */
    computedItems() {
      const itemsToCompute = _cloneDeep(this.unorderedItems ? this.enrichedItems : this.sortedEnrichedItems);

      this.moveItemsWithForcedIndex(itemsToCompute);
      this.moveUnlabelledGroupToTop(itemsToCompute);
      this.groupItemsByExclusivity(itemsToCompute);

      return this.processItems(itemsToCompute);
    },
    unlabelledGroup() {
      if (!this.isGrouped) return null;
      return this.items.find(item => this.isLabelledGroup(item) && item.label === UNLABELLED_GROUP_MATCHER);
    },
    // eslint-disable-next-line vue/no-unused-properties
    disabledGroups() {
      const disabledGroups = new Set();

      this.items.forEach((item) => {
        if (item.items && item.items.every(i => i.disabled)) {
          disabledGroups.add(item.label);
        }
      });

      return disabledGroups;
    },
    // eslint-disable-next-line vue/no-unused-properties
    firstSelectedValue() {
      return this.computedItems.find(item => item.selected)?.value;
    },
    // eslint-disable-next-line vue/no-unused-properties
    menuHeaderHeight() {
      return this.multiple ? this.$options.MENU_HEADER_HEIGHT : 0;
    },
    computedMenuProps() {
      const defaultProps = {
        contentClass: Object.keys(this.menuClasses)
          .filter(className => this.menuClasses[className])
          .join(' '),
      };

      if (this.menuMaxWidth) {
        defaultProps.maxWidth = this.menuMaxWidth;
      }

      return {
        ...defaultProps,
        ...this.menuProps,
      };
    },
    isFirstItemSelectable() {
      if (!this.hasItems) return false;

      return this.computedItems[0]?.value || this.computedItems[0]?.label === UNLABELLED_GROUP_MATCHER;
    },
    classes() {
      return {
        'deck-select--single': !this.multiple,
        'deck-select--multiple': this.multiple,
        'deck-select--searching': this.isSearching,
        'deck-select--disabled': this.disabled || this.loading,
        'deck-select--can-clear-all': this.canClearAll,
        'deck-select--has-exclusive-selected': this.hasCurrentExclusiveItemSelected,
        [`deck-select--${this.size}`]: true,
      };
    },
    /**
     * In order to query elements inside the detached root-level v-menu, we
     * gotta resort to inner modifiers that will be passed through :menuProps
     */
    menuClasses() {
      return {
        'deck-select__menu': true,
        'deck-select__menu--multiple': this.multiple,
        'deck-select__menu--single': !this.multiple,
        'deck-select__menu--padding-top': (!this.multiple && this.isFirstItemSelectable) || !this.hasItems,
        'deck-select__menu--disabled': this.disabled || this.loading,
      };
    },
    computedActions() {
      return this.actions.map(action => ({
        // Default props
        kind: 'secondary',
        size: 'small',
        isReady: true,
        ...action,
      }));
    },
    isGrouped() {
      return Boolean(this.items[0]?.label);
    },
    isSearching() {
      return this.autocompleteRef?.isSearching;
    },
    hasItems() {
      return this.isGrouped ? this.items?.some(group => group.items?.length) : this.items?.length;
    },
    canClearAll() {
      return !this.disabled && !this.deckInputMixin__isEmpty && !this.loading && !this.required;
    },
    hasExclusiveItems() {
      if (!this.multiple) return false;

      if (this.isGrouped) {
        return this.items?.some(group => group.items?.some(item => 'exclusive' in item));
      }

      return this.items?.some(item => 'exclusive' in item);
    },
    hasCurrentExclusiveItemSelected() {
      if (!this.hasExclusiveItems) return false;

      return this.computedItems.some(item => item.selected && 'exclusive' in item);
    },
    itemsSelection() {
      return this.getItemsSelectionState(this.enrichedItems);
    },
  },
  watch: {
    hasMenuOpen(newValue, oldValue) {
      if (newValue && !oldValue) {
        /**
         * Emited when the menu is opened.
         * @event menuOpen
         */
        this.$emit('menuOpen');
      } else if (!newValue && oldValue) {
        /**
         * Emited when the menu is closed.
         * @event menuClose
         */
        this.$emit('menuClose');
      }
    },
  },
  mounted() {
    if (this.matchMenuWidthToInput) {
      this.setupMenuMaxWidth();
    }
  },
  methods: {
    onInput(value, immediatelyBlur = false) {
      let newValue = _cloneDeep(value);

      if (this.multiple && this.hasExclusiveItems) {
        newValue = this.handleExclusiveItems(newValue, this.deckInputMixin__internalValue);
      }

      this.deckInputMixin__internalValue = newValue;

      if (immediatelyBlur || (!this.multiple && !this.deckInputMixin__isEmptyValue(newValue)) || this.hasExclusiveItemInArray(newValue)) {
        if (this.autocompleteRef) this.autocompleteRef.blur();
      }
    },
    onBlur() {
      if (this.required && this.deckInputMixin__isEmpty) {
        this.deckInputMixin__resetInternalValue();
      }

      this.searchInput = '';
      this.$emit('blur');
    },
    // Exposed to parent components to open the autocomplete menu
    // eslint-disable-next-line vue/no-unused-properties
    openAutocomplete() {
      this.hasMenuOpen = true;
      this.autocompleteRef.focus();
    },
    // Exposed to parent components to close the autocomplete menu
    // eslint-disable-next-line vue/no-unused-properties
    closeAutocomplete() {
      this.autocompleteRef.blur();
      this.hasMenuOpen = false;
    },
    handleExclusiveItems(newValue, oldValue) {
      const addedItems = _difference(newValue, oldValue);
      const addedExclusiveItem = addedItems.find(item => this.hasExclusiveItemInArray([item]));

      if (addedExclusiveItem) return [addedExclusiveItem];

      if (oldValue.some(item => this.hasExclusiveItemInArray([item])) && addedItems.length) {
        return newValue.filter(value => !this.hasExclusiveItemInArray([value]));
      }

      return newValue;
    },
    handleActionClick(action) {
      if (action && typeof action === 'function') action();
    },
    hasExclusiveItemInArray(items) {
      return items?.some(item => this.computedItems.some(({ value, exclusive }) => value === item && exclusive));
    },
    moveItemsWithForcedIndex(itemsToCompute) {
      // If no item has a 'forceIndex' property, return early
      if (!itemsToCompute.some(item => 'forceIndex' in item)) return;

      itemsToCompute.forEach((item) => {
        if ('forceIndex' in item) {
          this.moveItem(itemsToCompute, item, item.forceIndex);
        }
      });
    },
    moveUnlabelledGroupToTop(itemsToCompute) {
      // Only move the unlabelled group if it exists and it's not already at the top
      if (itemsToCompute.indexOf(this.unlabelledGroup) <= 0) return;

      this.moveItem(itemsToCompute, this.unlabelledGroup, 0);
    },
    groupItemsByExclusivity(itemsToCompute) {
      if (!this.hasExclusiveItems) return;

      const exclusiveGroups = [];
      const nonExclusiveGroups = [];

      itemsToCompute.forEach((group) => {
        if (group.items) {
          const exclusiveItems = group.items.filter(item => 'exclusive' in item);
          const nonExclusiveItems = group.items.filter(item => !('exclusive' in item));

          if (exclusiveItems.length > 0) {
            exclusiveGroups.push({ ...group, items: exclusiveItems, exclusive: true });
          }

          if (nonExclusiveItems.length > 0) {
            nonExclusiveGroups.push({ ...group, items: nonExclusiveItems });
          }
        }
      });

      itemsToCompute.splice(0, itemsToCompute.length, ...exclusiveGroups, ...nonExclusiveGroups);
    },
    processItems(itemsToCompute) {
      let selectAllAdded = false;

      return itemsToCompute.flatMap((item) => {
        const itemsArray = [item];

        if (this.isGrouped && item.items) {
          const groupLabel = { ...item };
          delete groupLabel.items;

          const groupItems = item.items.map(i => ({
            ...i,
            groupLabel: item.label,
            selectionText: this.isLabelledGroup(item) && !item.omitPrefix ? `${item.label} / ${i.text}` : i.text,
          }));

          itemsArray.push(...groupItems);
        }

        // If needed, add the "select all" item before the first non-exclusive item.
        if (!selectAllAdded && !item.exclusive && this.hasItems && this.multiple) {
          selectAllAdded = true;
          itemsArray.unshift({ selectAll: true });
        }

        return itemsArray;
      });
    },
    getItemsSelectionState(items) {
      const allowedItemsForSelection = items.flatMap(item => item.items || item).filter(item => !item.disabled && !item.exclusive);
      const selectedItemsCount = allowedItemsForSelection.filter(item => item.selected).length;

      let state = 'some';
      if (selectedItemsCount === 0) state = 'none';
      if (selectedItemsCount === allowedItemsForSelection.length) state = 'all';

      return {
        state,
        isNone: state === 'none',
        isAll: state === 'all',
        isSome: state === 'some',
      };
    },
    onSelectAll(items) {
      if (this.loading) return;

      const itemsSelection = this.getItemsSelectionState(items);

      if (this.selectAllDisabled && itemsSelection.isNone) return;

      const passedItems = items.flatMap(item => item.items || item);

      const updatedValue = (() => {
        // When selecting all:
        // If no items are selected, create a new array that includes all items
        // currently in internalValue and all items from the passedItems that
        // are not disabled, not already selected, and not exclusive
        if (itemsSelection.isNone) {
          const allowedItemsForSelection = passedItems
            .filter(item => !item.disabled && !item.selected && !item.exclusive)
            .map(item => item.value);

          return [...this.deckInputMixin__internalValue, ...allowedItemsForSelection];
        }

        // When clearing all:
        // If some or all items are selected, create a new array that includes
        // only the items that are currently in internalValue but not in the
        // selected items from passedItems
        return this.deckInputMixin__internalValue.filter(value => !passedItems.find(item => item.value === value && item.selected));
      })();

      this.onInput(updatedValue);
    },
    moveItem(items, item, newIndex) {
      const oldIndex = items.indexOf(item);
      if (oldIndex === -1 || oldIndex === newIndex) return;

      items.splice(oldIndex, 1);
      items.splice(newIndex, 0, item);
    },
    filterAlgorithm(_value, queryText, item) {
      const matchQuery = text => slugify(text?.toLocaleLowerCase()).includes(slugify(queryText?.toLocaleLowerCase()));
      // eslint-disable-next-line no-prototype-builtins
      const isLabelItem = item => item.hasOwnProperty('label');

      // If the item is a label, check if any following items until the next label match the queryText
      if (isLabelItem(item.raw)) {
        const followingItems = this.computedItems
          .slice(this.computedItems.indexOf(item) + 1);

        const nextLabelIndex = followingItems
          .findIndex(item => isLabelItem(item));

        // If there is no next label, check all following items. If there is a next label, only check the items up to the next label
        const itemsToCheck = nextLabelIndex === -1
          ? followingItems
          : followingItems.slice(0, nextLabelIndex);

        return itemsToCheck.some(item => matchQuery(item.raw?.selectionText) || matchQuery(item.raw?.text));
      }

      // If the item is not a label, check if it matches the queryText
      return matchQuery(item.raw?.selectionText) || matchQuery(item.raw?.text);
    },
    // TODO: We should actually check for a inexistent label property to be more
    // safe, but we currently depend on it being `required` in most of the label
    // rendering logic, so refactoring it to not rely on it is a bigger task.
    isLabelledGroup(item) {
      return item?.label !== UNLABELLED_GROUP_MATCHER;
    },
    setupMenuMaxWidth() {
      if (!this.autocompleteRef?.$el?.offsetWidth) {
        setTimeout(() => {
          this.setupMenuMaxWidth();
        }, 500);
      }

      this.menuMaxWidth = `${this.autocompleteRef?.$el?.offsetWidth || 100}px`;
    },
  },
});
</script>

<style lang="scss">
.deck-select {
  --deck-select-border-color: var(--z-input-border-color);
  --deck-select-input-color: var(--z-color-text);
  --deck-select-placeholder-color: var(--z-input-placeholder-color);

  display: flex;
  flex-direction: column;

  .v-menu .v-list {
    padding-block: 0 !important;
  }
}

.deck-select__autocomplete {
  display: inline-block;
  font-weight: 400;
  font-size: var(--deck-select-font-size);
  line-height: var(--z-line-height-base);

  .v-chip {
    margin: 0px !important;
  }

  .v-input__control {
    min-height: var(--deck-select-height) !important;
    color: var(--deck-select-input-color) !important;

    .v-field {
      min-height: var(--deck-select-height) !important;
      background-color: var(--z-input-background-color) !important;
      padding-inline: var(--z-s2) !important;
      border-radius: var(--z-border-radius-inner-base) !important;

      .v-field__outline {
        color: var(--deck-select-border-color);
      }

      .v-input__append-inner {
        align-self: center;
        margin: 0 !important;
      }

      .v-field__input {
        position: relative;
        display: inline-flex;
        min-height: var(--deck-select-height) !important;
        padding: 4px 0 !important;

        input {
          caret-color: var(--deck-select-input-color) !important;
          color: var(--deck-select-input-color) !important;
          min-width: 3ch;
          padding: 0;
          width: 100%;
          field-sizing: content;

          &::placeholder {
            color: var(--deck-select-placeholder-color);
          }
        }
      }

      .v-input__icon--clear {
        position: absolute;
        right: var(--z-s7) !important;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &:not(.v-field--focused) .v-field,
  &:not(.v-field--focused) .v-select__slot {
    cursor: pointer;
  }

  &:is(:hover, :focus-within, .v-field--focused):not(.v-input--is-disabled) {
    --deck-select-border-color: var(--z-input-border-color-highlight);
  }

  .v-autocomplete__menu-icon {
    font-size: 16px;
  }

  &.v-autocomplete--active-menu  .v-autocomplete__menu-icon {
    transform: unset;
  }
}

.deck-select--can-clear-all:not(.deck-select--disabled) .deck-select__autocomplete:hover {
  .deck-select__clear-button {
    opacity: 1;
    transform: translateX(0);
  }

  .deck-select__chevron {
    opacity: 0;
  }

  .deck-select__search-icon {
    opacity: 0;
  }
}
.deck-select__append-icons {
  display: grid;
  place-items: center;
  width: 28px;
  overflow: hidden;

  > * {
    grid-area: 1 / -1
  }
}

.deck-select__chevron {
  opacity: 1;
  transition: 100ms ease;
}

.deck-select__search-icon {
  opacity: 0;
  transition: 100ms ease;
}

.deck-select__clear-button {
  opacity: 0;

  // Same as slide-x-reverse
  transform: translateX(15px);
  transition: 300ms cubic-bezier(0.25, 0.8, 0.5, 1);
}

.deck-select--dense {
  --deck-select-height: 32px;
  --deck-select-font-size: var(--z-font-size-small);
}

.deck-select--medium {
  --deck-select-height: 40px;
  --deck-select-font-size: var(--z-font-size-medium);
}

.deck-select--single, .deck-select--has-exclusive-selected {
  &:is(:focus-within, .v-field--focused) {
    .deck-select__selection {
      opacity: 0;
    }
  }
}

.deck-select--multiple {
  &:not(.deck-select--has-exclusive-selected).v-input__control .v-field {
    padding-left: var(--z-s1) !important; // Less padding to opticaly align with the chips
  }

  .v-select__selections {
    margin-inline: var(--z-s1-n);

    input {
      padding-left: var(--z-s1) !important;
    }
  }

  &.deck-select--has-exclusive-selected {
    .v-select__selections input {
      margin-left: -6px;
    }
  }
}

.deck-select--searching {
  .v-select__selections {
    input {
      min-width: 48px !important;
    }
  }
}

.deck-select--disabled {
  --deck-select-placeholder-color: var(--z-input-placeholder-disabled-color);

  .v-select__selections {
    color: unset !important;
  }

  .deck-select__autocomplete {
    // There are a lot of elements with overriden cursors, so this is the easiest way to visually "override" them all when disabled
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: default;
      z-index: 1;
    }
  }
}

.deck-select__menu {
  --deck-select-menu-padding-block: var(--z-s2);

  background: var(--z-theme-background);
  border-radius: var(--z-border-radius-base) !important;

  .v-list {
    padding-top: 0;
    padding-bottom: var(--deck-select-menu-padding-block);
  }
}

.deck-select__menu--padding-top .v-list {
  padding-top: var(--deck-select-menu-padding-block);
}

.deck-select__menu--disabled {
  .deck-select__menu-append-wrapper {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--z-theme-background);
      opacity: 0.65;
      pointer-events: none;
    }
  }
}

.deck-select__menu-append-wrapper {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: var(--z-s3);
  min-height: 40px;
  background-color: var(--z-theme-background);
  margin-top: var(--z-s2);
  margin-bottom: calc(var(--deck-select-menu-padding-block) * -1); // compensate for padding-bottom so it touches the bottom
  box-shadow: 0 -1px 0 0 var(--z-elevation-color);
}

.deck-select__inner-menu-loading {
  position: sticky;
  margin-top: 4px; // loading bar itself is 4px tall
  margin-bottom: calc(var(--deck-select-menu-padding-block) * -1); // compensate for padding-bottom so it touches the bottom
}
</style>
